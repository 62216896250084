<template>
  <el-dialog
    custom-class="ql-dialog fit-width-content classroomDialog"
    :title="isEdit ? '编辑教室信息' : '新增教室信息'"
    :visible.sync="visible"
  >
    <el-form :model="formData" :rules="rules" ref="form" label-width="85px">
      <el-form-item label="教室名称" prop="classroomName" size="small">
        <el-input v-model.trim="formData.classroomName" placeholder="请输入教室名称" style="width: 400px" />
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button @click="handleCancel" size="small">取消</el-button>
      <el-button type="primary" v-debounceClick="handleSave" :loading="loading" size="small">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Button, Dialog, Input, Form, FormItem, Select, Option, Radio, RadioGroup } from 'element-ui';
import { saveClassroom } from '@/api/basic';

export default {
  name: 'ClassroomDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    dialogData: Object,

    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const validateClassName = (rule, value, callback) => {
      if ((value || '').trim().length === 0) {
        callback(new Error('教室名称不能为空'));
        return;
      }
      if (value.trim().length > 20) {
        callback(new Error('教室名称过长'));
        return;
      }

      callback();
    };

    return {
      loading: false,
      formData: {
        classroomName: '',
        classroomId: '',
      },

      rules: {
        classroomName: [
          {
            required: true,
            validator: validateClassName,
            trigger: 'blur',
          },
        ],
      },
    };
  },

  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  watch: {
    visible(val) {
      if (val) {
        this.formData = this.dialogData;
        this.loading = false;
      }
    },
  },

  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },

  methods: {
    handleSave() {
      this.loading = true;

      this.$refs.form.validate((valid) => {
        if (valid) {
          saveClassroom({
            classroomId: this.formData.classroomId,
            classroomName: this.formData.classroomName,
          })
            .then((res) => {
              if (res.status === 0) {
                this.$message.success(`${this.isEdit ? '编辑教室成功' : '新增教室成功'}`);
                this.$emit('success');
              }
            })
            .catch(() => (this.loading = false));
        } else {
          this.loading = false;
          return false;
        }
      });
    },

    handleCancel() {
      this.visible = false;
      this.$refs.form.clearValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
.classroomDialog {
  .tips {
    @apply T6 text-F2;
    padding: 8px 12px;
    background-color: #f8f8f8;
    border-radius: 4px;
  }
}
</style>
